exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-direccion-js": () => import("./../../../src/pages/direccion.js" /* webpackChunkName: "component---src-pages-direccion-js" */),
  "component---src-pages-empresa-js": () => import("./../../../src/pages/empresa.js" /* webpackChunkName: "component---src-pages-empresa-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politicas-js": () => import("./../../../src/pages/politicas.js" /* webpackChunkName: "component---src-pages-politicas-js" */),
  "component---src-pages-servicios-js": () => import("./../../../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */),
  "component---src-pages-tiendas-recomendadas-js": () => import("./../../../src/pages/tiendas-recomendadas.js" /* webpackChunkName: "component---src-pages-tiendas-recomendadas-js" */)
}

