import React from "react"
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"
import "@fontsource/roboto"

import AuthProvider from "./src/context/AuthContext"

export const wrapRootElement = ({ element }) => (
  <AuthProvider>{element}</AuthProvider>
)
